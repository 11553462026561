<template>
<div>
  <div slot="header" class="modal-header popup-header" style="background-color:#0094D1">
    <span class="modal-title-popup header_color"><b><span v-if="employee_details.first_name">Hello {{employee_details.first_name}}</span> <span v-if="employee_details.last_name">{{employee_details.last_name}}</span></b></span>			
		</div>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Employees-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Employees Body-->
                <div class="row justify-content-center ">
                    <div class="col-xl-12">
                        <!--begin: Wizard Form-->
                         <el-form ref="form" :model="form" auto-complete="nope">
                            <!--begin: Wizard Step 1-->
                            <div data-wizard-type="step-content" data-wizard-state="current">
                                <div class="row">
                                    <div class="col-xl-12">
                                        <!-- <div class="row">                                         
                                            <div class="col-xl-12">                                             
                                              <span >
                                               <h6>{{check_time}} {{checkTime.hours > 13 ? checkTime.hours - 12 : checkTime.hours == 0?12:checkTime.hours }} : {{checkTime.minutes}} {{checkTime.hours >= 12 ? 'PM' : 'AM'}}</h6> 
                                              </span>                                                
                                            </div>  
                                             <div class="col-xl-12 mt-2">
                                               <h6 v-if="!form.record_time_out">{{record_time}} {{form.record_time_in}} {{form.in_meridiem}}</h6>  
                                               <h6 v-else>{{record_time}} {{form.record_time_out}} {{form.out_meridiem}}</h6>                                                                                                
                                            </div>
                                                                                     
                                        </div> -->
                                    </div> 
                                         <div class="col-xl-12 mt-5">                                              
                                             <h6 v-if="form.status=='Check IN'">Would You Like <b class="active">{{form.status}}</b> ?</h6> 
                                              <h6 v-else>Would You Like <b class="red">{{form.status}}</b> ?</h6>                                                                 
                                        </div>                                                                                                        
                                </div>
                            </div>

                            <!--begin: Employees Actions -->
                           <div class="d-flex justify-content-between pt-10">
                                <a @click.prevent="CheckIn" class="text-white" >
                                    <div class="btn btn-primary font-weight-bold text-uppercase" style="width:150px;">
                                       Yes
                                    </div>
                                </a>
                                 <a @click="closeForm" class="text-white" >
                                    <div class="btn btn-danger font-weight-bold text-uppercase" style="width:150px;">
                                       No
                                    </div>
                                </a>
                            </div>
                            <!--end: Employees Actions -->
                        </el-form>
                        <!--end: Employees Form-->
                    </div>
                </div>
                <!--end: Employees Body-->
            </div>
        </div>
        <!--end: Employees-->
    </div>
</div>
</template>

<script>
import Form from '@/core/services/api.form.services'
import successMsg from "@/components/employeeCheckIn/success_message";
//import {globalEvents} from '@/utils/globalEvents';
export default {
  name: 'create-term',
  props: {
    employee: {
      type: Object,
      required: true
    },
   checkTime: {
      type: Object,
      required: true
    },
     loginDetails: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      loading : false,
      error:null,
      form : new Form({      
       employee_id:null,
       actual_time_in:null,
       actual_time_out:null,
       record_time_in:null,
       record_time_out:null,
       status:null,
       checkin_type:null,
       in_meridiem:null, 
       out_meridiem:null,
       total_hours:0,
       lunch_break:0
      }),
       submit_btn_text: 'Add Term',
       employee_details : [],
       record_minutes : null,
       checkin_url : 'employee/time/check',
       //check_time :'Your Check In Time at',
       //record_time : 'Your Record In Time at'
     }
  },
  methods: {
    reset() {
      this.form.reset()
      this.$refs['form'].resetFields()
    },
    closeForm() {
      if (!this.form.submiting) {
        this.$emit('close')
      }
    },
    CheckIn() {
      this.form.post(this.checkin_url).then((response) => {         
         this.$modal.show(
              successMsg,
              {
               message:response.message,
               status:response.checkStatus 
              },
              {
                draggable: true,
                transition: "pop-out",
                height: "auto",
                clickToClose: true,
                adaptive: true,
                width: "400px",
              }
            );      
        this.$emit('close')
      }).catch((error) => {
        console.log(error);
      })
    },
     timeConvert(n) {     
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      if(rminutes == 0){
        rminutes = '00'
      }
      return  rhours + ' : ' + rminutes ;
      },   
      totalRecordInTime(time){        
        var splitTimeIn= time.split(':'); 
        var record_in = (parseInt(splitTimeIn[0]))*60+(parseInt(splitTimeIn[1]))
        var hour=0;   
        if(record_in >= 60){  
          hour = this.timeConvert(record_in);
        }else{
          hour = '0 : '+record_in
        }  
        return hour;      
      },
       timeCalculation(time1, time2){ 
        var Time1 = time1
        var Time2 = time2
        var splitTime1= Time1.split(':');
        var splitTime2= Time2.split(':');
        var hour=0;
        var ct_hour = (parseInt(splitTime1[0]))*60+(parseInt(splitTime1[1]))        
        var record_in = (parseInt(splitTime2[0]))*60+(parseInt(splitTime2[1]))
        
        var h = 0;
        if(ct_hour > record_in){
           h = ct_hour - record_in
        }else{
          h = 0
        }
      
        if(h>=60){  
          hour = this.timeConvert(h);
        }else{
          hour = '0 : '+h
        }  
            return hour;           
      },   
      totalCalculation(timeOut, timeIn){   
          
        var splitTimeOut= timeOut.split(':');
        var splitTimeIn= timeIn.split(':');
        var hour=0;
        var TimeIn = 0
        var TimeOut = 0
       
        if(this.loginDetails.in_meridiem == "AM"){
            TimeIn = (parseInt(splitTimeIn[0]))*60+(parseInt(splitTimeIn[1]));  
        }else{
            
              TimeIn = (parseInt(splitTimeIn[0])+12)*60+(parseInt(splitTimeIn[1]))          
              
        }
         
        if(this.form.out_meridiem == "AM"){
             TimeOut = (parseInt(splitTimeOut[0]))*60+(parseInt(splitTimeOut[1]))
        }else{
                         
           if(parseInt(splitTimeOut[0]) == 12){
              TimeOut = (parseInt(splitTimeOut[0])*60)+(parseInt(splitTimeOut[1]))
           }else{
              TimeOut = (parseInt(splitTimeOut[0])+12)*60+(parseInt(splitTimeOut[1])) 
           }  
        }
           
        var h = 0;
        if(TimeOut > TimeIn){
           h = TimeOut - TimeIn
        }else{
           h = 0
        }      
       
        if(h>=60){  
          hour = this.timeConvert(h);
        }else{
          hour = '0 : '+h
        }       
         
            return hour;       
      },   
  },
  
  created() {
      this.employee_details = this.employee;
      if(this.employee_details){
          this.form.employee_id = this.employee_details.id
      }
      if(this.checkTime){
         if(!this.loginDetails){
          
                if(this.checkTime.minutes > 4 && this.checkTime.minutes <= 19){
                this.record_minutes =  15
                }else if(this.checkTime.minutes > 19 && this.checkTime.minutes <=34){
                    this.record_minutes = 30
                }else if(this.checkTime.minutes > 34 && this.checkTime.minutes <=49){
                    this.record_minutes = 45
                }else if(this.checkTime.minutes > 49 && this.checkTime.minutes <=60){
                    this.record_minutes = 60
                }else{
                    this.record_minutes = '00'
                }
                
         let hours = this.checkTime.hours > 12 ? this.checkTime.hours - 12 : this.checkTime.hours 
         if(hours == 0){
              hours = 12
            }
         this.form.actual_time_in = hours+' : '+this.checkTime.minutes;
         let recordTime = hours+' : '+this.record_minutes; 
            
         this.form.record_time_in = this.totalRecordInTime(recordTime);
         
               
         this.form.in_meridiem = this.checkTime.hours >= 12 ? 'PM' : 'AM';
         let total_min = parseInt(hours*60 + this.checkTime.minutes);
          if( this.form.in_meridiem == 'AM'){
              if(total_min <= 424){
                this.form.record_time_in = '7 : 00'
          }  

          }        
         
         let recordMin = parseInt(this.checkTime.hours*60 + this.record_minutes);
         
         if(this.form.in_meridiem == 'AM'){
           if(recordMin > 421){
             this.form.checkin_type = 'late'
           }
         }else{
           this.form.checkin_type = 'late'
         }        
         this.form.status = 'Check IN'

         }else{
            if(this.loginDetails){
              
              if(this.checkTime.minutes < 12){
                 this.record_minutes =  '00'
               }else if(this.checkTime.minutes > 11 && this.checkTime.minutes < 26){
                    this.record_minutes =  15
                }else if(this.checkTime.minutes > 25 && this.checkTime.minutes < 42){
                    this.record_minutes = 30                
                }
                else if(this.checkTime.minutes > 41 && this.checkTime.minutes < 56){
                    this.record_minutes = 45
                } 
                else if(this.checkTime.minutes > 55 && this.checkTime.minutes <= 60){
                    this.record_minutes = 60
                }
            
            let hours = this.checkTime.hours > 12 ? this.checkTime.hours - 12 : this.checkTime.hours
            if(hours == 0){
              hours = 12
            }
            let record_time_out = hours+' : '+this.record_minutes;
            this.form.record_time_out = this.totalRecordInTime(record_time_out);
            this.form.actual_time_out = hours +' : '+this.checkTime.minutes;
            //let actualTime = hours +'.'+this.checkTime.minutes;
            this.form.status = 'Check Out'            
            this.form.out_meridiem = this.checkTime.hours >= 12 ? 'PM' : 'AM';  
             
            this.form.total_hours = this.totalCalculation(this.form.record_time_out,this.loginDetails.record_time_in);
           
            var breakTime = this.form.total_hours.split(':');
            var totalMin =  (parseInt(breakTime[0])*60)+parseInt(breakTime[1]);            
            if(totalMin > 270){
              this.form.lunch_break = 30
              var totalMinutes = totalMin - 30           
              this.form.total_hours = this.timeConvert(totalMinutes);
            }
         
        
           }
         }  
      } 
    
  },
    mounted() {
        if(this.supplier_id){
            this.form.supplier_id = this.supplier_id
        }

        
    }
}

</script>

<style scoped>
.option-info{
  float: left; color: #8492a6; font-size: 13px; clear:both;line-height: 20px;
}
.option-info i {
  color: #27304A;
}
.title{
  font-size: 16px;
  font-weight: 600;
  color: #27304A;
  display: inline-block;
  margin-bottom: 20px;
}
.event-container {
  background-color: white;
  padding: 20px;
  border: 1px solid #ebeef5;
}
.icon-pointer{
  font-size: 20px; cursor: pointer;color: #616e8e;
}
.header_color{
    color: #fff;
}

</style>
