<template>
    <div  style="width:100%; background-color:#E8E8E9">
      <div class="header_checkin">
       <img :src="logo"  style="height:50px;"/>
      </div>
      <el-form>          
       <div class="row mt-10">
               <div class="col-xl-3 col-sm-3 col-md-3"></div>
               <div class="col-xl-6 col-sm-6 col-md-6 login_card">
                  <div class="mt-20" style="text-align:center;">
                    <p class="date">{{dateTime.date}}</p>
                    <div class="digital_clock">
                        <span>
                           <span class="mr-3 ml-3" v-if="dateTime.hours ==0">12</span>
                          <span class="mr-3 ml-3" v-else>{{ dateTime.hours > 12 ? dateTime.hours - 12 : dateTime.hours}}</span>
                          :<span class="ml-3 mr-3">{{ dateTime.minutes }}</span>:<span class="ml-3 mr-3">{{ dateTime.seconds }}</span> </span>
                        <span  style="padding:5px; border-left:2px solid #cdcdd7 "><span class="mr-3 ml-3">{{dateTime.hours >= 12 ? "PM" : "AM"}}</span></span>
                    </div>                    
                  </div>
                   <div>                  
                       <div class="login_header">Employee Login</div>
                    </div>
                    <div class="login_input">
                    <div class="form-group">
                        <label style="float:left;">Enter Employee ID</label>
                        <input @keyup.enter="employeeIdValidation"  class="form-control form-control-solid form-control-lg" v-model="form.employee_id"  type="text"/>
                        <input
                        hidden
                          type="text"
                          class="form-control form-control-solid form-control-lg"                         
                        />
                      </div>
                       <div  style="float:left; margin-top: -12px;">
                           <span v-if="employeeId_error" class="red">{{employeeId_error}}</span>
                       </div>
                    </div>
                    <div class="login_button" v-loading="loading">
                         <el-button type="primary" @click.prevent ="employeeIdValidation()">
                           Check In / Check Out
                         </el-button>
                    </div>                    
                </div>
               <div class="col-xl-3 col-sm-3 col-md-3"></div>
           </div>
      </el-form>
      
    </div>
</template>

<script>
import Form from "@/core/services/api.form.services";
import employeeConfirm from "@/components/employeeCheckIn/employeeConfirmation";
import moment from 'moment-timezone';
import {
 getTime
} from "@/api/employeeLogin";
const date = new Date();
const candaTime = moment.tz.setDefault('America/Toronto');
export default {
    name: "add-order",
  data() {
    return {
       loading:false,
       dateTime: {
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: date.getSeconds(),
        date : candaTime(date).format('MMM DD, YYYY'),
      },
      timer: undefined,
      form: new Form({
        employee_id: null,
      }),
      employeeId_error: null,
      logo : '../media/images/tevola-time-clock.svg'
    }
  },
  methods: {
    setDateTime() {     
       getTime().then((response) => {  
        const serverDate = response.data;
        const date = new Date(serverDate);
        this.dateTime = {
          hours: date.getHours(),
          minutes: ('0'+date.getMinutes()).slice(-2),
          seconds: ('0'+date.getSeconds()).slice(-2),
          date : candaTime(date).format('MMM DD, YYYY'),
        };
      });
     
    },
   
    employeeIdValidation(){    
         this.loading = true;
         this.employeeId_error = ''
         let url = "employeeId/validation"
     this.form.post(url).then((response) => {
          if (response.status == false) {           
            this.loading = false;
            this.employeeId_error = response.message; 
            //this.$showResponse("success", response.message);
          }else{
             this.loading = false;
             let loginDetails = response.loginDetails;
             this.$modal.show(
              employeeConfirm,
              {
                employee: response.data, checkTime : this.dateTime, loginDetails : loginDetails
              },
              {
                draggable: true,
                transition: "pop-out",
                height: "auto",
                clickToClose: true,
                adaptive: true,
                width: "400px",
              }
            );
          }
        }).catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
     getServerTime() {
      this.loading = true;
      getTime().then((response) => {
       this.setDateTime(response.data);
     const serverDate = new Date(response.data);
      console.log(serverDate);
        this.loading = false;
      });
    },
  },
  beforeMount() {
    this.timer = setInterval(this.setDateTime, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  created() {
    this.getServerTime();
  }
}
</script>
<style scoped>
.header_checkin{
    padding: 15px;
    background-color: #fff;
    text-align: center;
}
.digital_clock{
    border: 2px solid #cdcdd7;   
    border-radius: 7px;
    background-color: #F2F2F2;
    font-size: 40px;
    margin: auto;
    width: 350px;
    margin-top: 15px;
    color: #0094D1;
    font-weight: 600;
    padding: 5px;
   
}
.check_date{
    border: 2px solid #cdcdd7;   
    border-radius: 7px;
    background-color: #F2F2F2;
    font-size: 32px;
    margin: auto;
    width: 265px;
    margin-top: 65px;
    color: #00000087;
    font-weight: 600;
}
.login_card{
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.login_header{
    font-size: 20px;
    padding: 45px;
    font-weight: 600;
    text-align: center;
}
.login_button{
    text-align: center;    
    color: #fff;
    margin-bottom: 60px;
}
.login_input{
    text-align: center;
    width: 60%;
    margin: auto;
    margin-bottom: 50px;
}
.success_message{
  background-color: red;
}
#clock {
    font-family: 'Share Tech Mono', monospace;
    color: #ffffff;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #daf6ff;
    text-shadow: 0 0 20px rgba(10, 175, 230, 1),  0 0 20px rgba(10, 175, 230, 0);
}
    .time {
        letter-spacing: 0.05em;
        font-size: 80px;
        padding: 5px 0;
    }
    .date {
        letter-spacing: 0.1em;
        font-size: 24px;
        color: #0094D1;
        text-shadow: 0 0 20px rgb(104 130 139), 0 0 20px rgb(10 175 230 / 0%);
    }
    .text {
        letter-spacing: 0.1em;
        font-size: 12px;
        padding: 20px 0 0;
    }
</style>