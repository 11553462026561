<template>
    <div class="content" v-if="status=='CheckIn'">
        <div class="success_msg"><i class="el-icon-circle-check success_icon"></i>
            {{message}}
        </div>
    </div>
    <div class="content" v-else>
        <div class="success_msg"><i class="el-icon-circle-check success_icon"></i>
            {{message}}
        </div>
    </div>
</template>

<script>
export default {
    props: {
    message: {
      type: String,
      required: true
    },
    status:{
      type: String,
      required: true
    },
    },
     data() {
        return {
            timer: undefined,
        }
     },
     methods:{        
         closeForm() {    
            this.$emit('close')      
        },

     },
  beforeMount() {
    this.timer = setInterval(this.closeForm, 1000);
  },
}
</script>

<style>
.content{
    text-align: center;
    color: #fff;
    background: #00D162;
    font-size: 20px;
    font-weight: bold;
    padding: 20px
}

.success_icon{
    color: #fff;
    margin-top: -10px;
    font-size: 35px;
}
.success_msg{
    font-size: 20px;
}
</style>